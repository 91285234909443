import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/navigation/navigation.min.css';

import { Navigation, Pagination } from 'swiper';
import React, { useRef, useState } from 'react';

import FacebookReview from './FacebookReview';
import Icon from '../Icon';
import Modal from './Modal';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper/lib/index';
import styled from 'styled-components';
import useWindowSize from '../../utils/hooks/useWindowSize';

const settings = {
  slidesPerView: 'auto',
  spaceBetween: 30,
  freeMode: true,
  modules: [Navigation, Pagination],
  containerClass: 'swiper-container facebook-review-container',
  pagination: {
    el: '.swiper-pagination.facebook-reviews-pagination',
    type: 'bullets',
    clickable: true,
    dynamicBullets: true,
    dynamicMainBullets: 1
  },
  breakpoints: {
    768: {
      spaceBetween: 15
    }
  }
};

const mobileStyle = {
  width: 300,
  height: 170,
  paddingBottom: 10
};

const normalStyle = {
  width: 370,
  height: 210,
  paddingBottom: 20
};

/**
 * @return {null}
 */
const FacebookReviews = ({ facebookReviews, withBorder, ...rest }) => {
  const [isShowModal, setShowModal] = useState(false);
  const [selectedReview, setSelectedReview] = useState({});
  const [swiper, setSwiper] = useState(null);
  const w = useWindowSize();
  const isMobile = w.innerWidth <= 768;
  const wrapperRef = useRef(null);

  const changeReview = type => {
    const currentIndex = facebookReviews.indexOf(selectedReview);
    const nextReview =
      facebookReviews[(currentIndex + 1) % facebookReviews.length];
    const prevReview =
      facebookReviews[(currentIndex - 1) % facebookReviews.length] ||
      facebookReviews[facebookReviews.length - 1];

    switch (type) {
      case 'next':
        setSelectedReview(nextReview);
        break;
      case 'prev':
        setSelectedReview(prevReview);
        break;
      default:
        break;
    }
  };

  if (!facebookReviews) {
    return null;
  }

  return (
    <Wrapper {...rest} ref={wrapperRef}>
      <SwiperWrapper>
        <Swiper {...settings} getSwiper={setSwiper}>
          {facebookReviews.map(review => (
            <div
              style={isMobile ? mobileStyle : normalStyle}
              key={`facebook-review-${review.id}`}
            >
              <FacebookReview
                withBorder={withBorder}
                photo={{ wide: review.photo }}
                fullName={review.fullName}
                reviewUrl={review.reviewUrl}
                reviewContent={review.reviewContent}
                courseName={review.metadata}
                onClick={() => {
                  if (isMobile) {
                    window.open(review.reviewUrl, '_blank');
                  } else {
                    setShowModal(true);
                    setSelectedReview(review);
                  }
                }}
              />
            </div>
          ))}
        </Swiper>

        <Next
          onClick={() => {
            if (swiper) swiper.slideNext();
          }}
        />
        <Prev
          onClick={() => {
            if (swiper) swiper.slidePrev();
          }}
        />
      </SwiperWrapper>

      <Modal
        show={isShowModal}
        onPrev={() => changeReview('prev')}
        onNext={() => changeReview('next')}
        onClose={() => {
          setShowModal(false);
          setSelectedReview({});
        }}
        reviewUrl={selectedReview.reviewUrl}
      />
    </Wrapper>
  );
};

FacebookReviews.propTypes = {
  facebookReviews: PropTypes.arrayOf(PropTypes.shape({})),
  withBorder: PropTypes.bool
};

const Wrapper = styled.div``;

const SwiperWrapper = styled.div`
  position: relative;

  @media screen and (min-width: 769px) {
    padding: 0 5.5em;
  }

  .swiper-container.facebook-review-container {
    padding-bottom: 30px;

    .facebook-reviews-pagination {
      .swiper-pagination-bullet {
        width: 5px;
        height: 5px;
        border-radius: 50%;

        &.swiper-pagination-bullet-active {
          background: #6f6f6f;
        }
      }
    }

    @media screen and (min-width: 769px) {
      .facebook-reviews-pagination {
        display: none;
      }
    }
  }
`;

const Next = styled(Icon).attrs({ type: 'NextArrowIcon' })`
  position: absolute;
  top: calc(50% - 66px);
  z-index: 1;
  right: 10px;
  cursor: pointer;
  font-size: 5em;

  @media screen and (max-width: 850px) {
    display: none;
  }
`;

const Prev = styled(Icon).attrs({ type: 'PrevArrowIcon' })`
  position: absolute;
  top: calc(50% - 66px);
  z-index: 1;
  left: 10px;
  cursor: pointer;
  font-size: 5em;

  @media screen and (max-width: 850px) {
    display: none;
  }
`;

export default FacebookReviews;
