import React, { useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import Icon from '../Icon';
import PropTypes from 'prop-types';
import { pulse } from 'react-animations';
import { useTranslation } from 'react-i18next';

const VideoWithTitleAndDescription = ({
  title,
  descriptions,
  videoUrl,
  ...rest
}) => {
  const videoRef = useRef(null);
  const [playing, setPlay] = useState(false);
  const { t } = useTranslation('common');

  return (
    <Wrapper {...rest}>
      {videoUrl && !playing && (
        <InfoContainer>
          <div>
            {title && <Title>{title}</Title>}

            <Descriptions child={descriptions.length}>
              {descriptions &&
                descriptions.map(description => (
                  <li key={description}>{description}</li>
                ))}
            </Descriptions>
          </div>
          <PlayButton
            onClick={() => {
              if (videoRef) {
                videoRef.current.play();
                setPlay(true);
              }
            }}
          >
            <PlayIcon />
            <PlayButtonText>{t('Watch video')}</PlayButtonText>
          </PlayButton>
        </InfoContainer>
      )}

      {videoUrl && (
        <Video
          isPlaying={playing}
          src={videoUrl}
          ref={videoRef}
          controls={playing}
          onPause={() => {
            setPlay(false);
          }}
        />
      )}
    </Wrapper>
  );
};

const pulseAnimate = keyframes`${pulse}`;

const Video = styled.video`
  object-fit: cover;
  width: 100%;
  opacity: 0;
  transition: opacity 1s;

  ${({ isPlaying }) =>
    isPlaying &&
    css`
      opacity: 1;
    `};

  @media screen and (max-width: 768px) {
    display: none;

    ${({ isPlaying }) =>
      isPlaying &&
      css`
        display: block;
      `};
  }
`;

const PlayButtonText = styled.span`
  font-weight: 300;
  font-size: 1.125em;
  margin-left: 0.5em;
`;

const PlayIcon = styled(Icon).attrs({ type: 'PlayCircleIcon' })`
  font-size: 3.5em;

  @media screen and (max-width: 768px) {
    font-size: 3em;
  }
`;

const PlayButton = styled.button`
  outline: 0;
  border: 0;
  display: flex;
  align-items: center;
  background-color: transparent;
  cursor: pointer;

  &:hover ${PlayIcon} {
    animation: ${pulseAnimate} 0.5s infinite;
  }
`;

const Descriptions = styled.ul`
  list-style-type: none;
  margin-left: 0;
  margin-top: 1.5em;
  max-width: 80%;

  ${({ child }) =>
    child > 1 &&
    css`
      list-style-type: decimal;
      margin-left: 1.45rem;
    `};

  @media screen and (max-width: 768px) {
    margin-top: 1em;

    li {
      margin-bottom: 0.25em;
    }
  }
`;

const Title = styled.p`
  font-size: 1.75em;
  font-weight: 500;
  color: ${({ theme }) => theme.darkBlueGrey};

  @media screen and (max-width: 768px) {
    font-size: 1.25em;
  }
`;

const InfoContainer = styled.div`
  position: absolute;
  padding: 1em;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-flow: column nowrap;
  z-index: 1;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    position: relative;
    height: auto;
  }
`;

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  position: relative;
  max-height: 320px;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    max-height: unset;
  }
`;

VideoWithTitleAndDescription.propTypes = {
  descriptions: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  videoUrl: PropTypes.string
};

VideoWithTitleAndDescription.defaultProps = {
  descriptions: null,
  title: null,
  videoUrl: null
};

export default VideoWithTitleAndDescription;
