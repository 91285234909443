import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import React from 'react';

const middleBreakpoint = 800;

const Picture = ({ src, alt, sources = [], preload, ...props }) => {
  const [firstItem, lastItem] = sources.filter(i => i.preload) || [null, null];

  return (
    <>
      <picture {...props}>
        {sources.map(i => (
          <source
            key={i.src}
            media={`(min-width: ${i.width}px)`}
            srcSet={i.src}
          />
        ))}
        <img src={src} alt={alt} />
      </picture>

      {preload && (
        <Helmet>
          {firstItem && lastItem ? (
            <>
              <link
                href={lastItem.src}
                rel="preload"
                as="image"
                media={`(max-width: ${middleBreakpoint}px)`}
              />
              <link
                href={firstItem.src}
                rel="preload"
                as="image"
                media={`(min-width: ${middleBreakpoint + 1}px)`}
              />
            </>
          ) : (
            <link href={src} rel="preload" as="image" />
          )}
        </Helmet>
      )}
    </>
  );
};

Picture.propTypes = {
  alt: PropTypes.any,
  preload: PropTypes.any,
  sources: PropTypes.array,
  src: PropTypes.any
};

export default Picture;
