import styled from 'styled-components';

export const RedTitle = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.125em;
  text-align: center;
  text-transform: uppercase;
  color: ${p => p.theme.brand};
`;

export const TitleWithUnderline = styled.h3`
  font-size: 2.3em;
  color: #2c3841;
  position: relative;
  text-align: center;

  &::after {
    position: absolute;
    bottom: -0.5em;
    left: calc(50% - 50px);
    content: '';
    width: 100px;
    height: 5px;
    background-color: #bb2b22;
  }

  @media screen and (max-width: 425px) {
    font-size: 1.5rem;
    text-align: center;
    padding: 0 10%;
    &::after {
      display: none;
    }
  }
`;

export const H1 = styled.h1`
  font-size: 3em;
  letter-spacing: 0.4px;
  color: ${p => p.theme.darkBlueGrey};
`;

export const H2 = styled.h2`
  font-size: 2.5em;
  letter-spacing: 0.4px;
  color: ${p => p.theme.darkBlueGrey};
`;

export const H3 = styled.h3`
  font-size: 2em;
  letter-spacing: 0.4px;
  color: ${p => p.theme.darkBlueGrey};
`;

export const H4 = styled.h4`
  font-size: 1.5em;
  letter-spacing: 0.4px;
  color: ${p => p.theme.darkBlueGrey};
`;

export const H5 = styled.h5`
  font-size: 1em;
  letter-spacing: 0.4px;
  color: ${p => p.theme.darkBlueGrey};
`;

export const H6 = styled.h6`
  font-size: 0.8em;
  letter-spacing: 0.4px;
  color: ${p => p.theme.darkBlueGrey};
`;
