import { Navigation, Pagination } from 'swiper';
import styled, { css } from 'styled-components';

import Cover from '../Cover';
import GraphImg from '../GraphImg';
import Icon from '../Icon';
import PropTypes from 'prop-types';
/* eslint-disable react/display-name */
import React from 'react';
import Swiper from 'react-id-swiper';

const swiperWithBullets = {
  modules: [Navigation, Pagination],
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true
  },
  navigation: {
    nextEl: '.NavigateCircleButton--next',
    prevEl: '.NavigateCircleButton--prev'
  },
  renderNextButton: function () {
    return <ModalForwardIcon className="NavigateCircleButton--next" />;
  },
  renderPrevButton: function () {
    return <ModalBackwardIcon className="NavigateCircleButton--prev" />;
  }
};

const ModalForwardIcon = styled(Icon).attrs({ type: 'NextArrowIcon' })`
  position: absolute;
  bottom: 20px;
  z-index: 1;
  left: 90px;
  cursor: pointer;
  font-size: 5em;

  @media screen and (max-width: 850px) {
    display: none;
  }
`;

const ModalBackwardIcon = styled(Icon).attrs({ type: 'PrevArrowIcon' })`
  position: absolute;
  bottom: 20px;
  z-index: 1;
  left: 10px;
  cursor: pointer;
  font-size: 5em;

  @media screen and (max-width: 850px) {
    display: none;
  }
`;

const Slider = ({ sliders, bullets, text, navigations, maxWidth }) => {
  const settings = bullets ? swiperWithBullets : {};

  if (!navigations) {
    Object.assign(settings, {
      renderPrevButton: function () {
        return null;
      },
      renderNextButton: function () {
        return null;
      }
    });
  }

  return (
    <Swiper {...settings}>
      {sliders &&
        sliders.map(({ id, image, ...slider }, index) => (
          <Wrapper key={id}>
            {index === 0 ? (
              <ImgWrapper>
                <GraphImg image={image} preload />
              </ImgWrapper>
            ) : (
              <Cover
                cover={image}
                style={{ minHeight: '500px' }}
                maxWidth={maxWidth}
              />
            )}
            {text && (
              <BannerSlogan
                textColor={slider.textColor}
                developer={slider.introduction}
                slogan={slider.slogan}
                subSlogan={slider.subSlogan}
              />
            )}
          </Wrapper>
        ))}
    </Swiper>
  );
};

export const BannerSlogan = ({
  withDash,
  developer,
  slogan,
  subSlogan,
  textColor,
  ...rest
}) => {
  return (
    <SloganWrapper {...rest} textColor={textColor}>
      <Developer>
        {withDash && <LongDash />}
        {developer}
      </Developer>
      <Slogan>{slogan}</Slogan>
      <SubSlogan>{subSlogan}</SubSlogan>
    </SloganWrapper>
  );
};

BannerSlogan.propTypes = {
  withDash: PropTypes.bool,
  developer: PropTypes.string,
  slogan: PropTypes.string,
  subSlogan: PropTypes.string,
  textColor: PropTypes.string
};

BannerSlogan.defaultProps = {
  withDash: true,
  developer: '',
  slogan: '',
  subSlogan: '',
  textColor: '#fff'
};

const LongDash = styled.span`
  display: inline-block;
  width: 28px;
  border-top: 1px solid white;
  border-top-color: inherit;
`;

const SloganWrapper = styled.div`
  ${({ textColor }) =>
    textColor &&
    css`
      color: ${textColor};
      border-top-color: ${textColor};
    `};
`;

const Developer = styled.h3`
  font-size: 1.125em;
  text-transform: uppercase;
  color: inherit;
  font-weight: 300;
  display: flex;
  align-items: center;

  ${LongDash} {
    margin-right: 10px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Slogan = styled.h1`
  font-size: 3em;
  letter-spacing: 1px;
  color: inherit;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 10px;

  @media screen and (max-width: 768px) {
    font-size: 2.5em;
  }

  @media screen and (max-width: 425px) {
    font-size: 2em;
  }
`;

const SubSlogan = styled.h2`
  font-size: 2.125em;
  letter-spacing: 1px;
  color: inherit;
  font-weight: 300;

  @media screen and (max-width: 768px) {
    font-size: 1.875em;
  }

  @media screen and (max-width: 425px) {
    font-size: 1.5em;
  }
`;

const ImgWrapper = styled.div`
  min-height: 500px;
  img {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  max-height: 630px;
  position: relative;

  ${SloganWrapper} {
    position: absolute;
    top: 15%;
    left: 5em;

    @media screen and (max-width: 768px) {
      max-width: 50%;
      left: 2em;
    }

    @media screen and (max-width: 425px) {
      max-width: 80%;
      top: 10%;
    }
  }
`;

Slider.propTypes = {
  sliders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  bullets: PropTypes.bool,
  text: PropTypes.bool,
  navigations: PropTypes.bool,
  maxWidth: PropTypes.number
};

Slider.defaultProps = {
  bullets: true,
  text: true,
  navigations: true
};

export default Slider;
