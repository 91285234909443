import React from 'react';
import ReactModal from 'react-responsive-modal';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { EmbeddedPost, FacebookProvider } from 'react-facebook';
import c from '../../constant';
import Icon from '../Icon';

const modalStyle = {
  modal: {
    minWidth: 320,
    padding: 0,
    borerTopLeftRadius: 30,
    borerTopRightRadius: 30
  },
  overlay: {
    padding: '2em'
  }
};

/**
 * @return {null}
 */
const Modal = ({ show, onClose, onNext, onPrev, reviewUrl }) => {
  if (!reviewUrl) {
    return null;
  }

  return (
    <ReactModal
      open={show}
      onClose={onClose}
      showCloseIcon={false}
      styles={modalStyle}
      center
    >
      <Prev onClick={onPrev} />
      <Next onClick={onNext} />
      <FacebookContainer>
        <FacebookProvider appId={c.FACEBOOK_APP_ID} onClose={onClose} open>
          <EmbeddedPost href={reviewUrl} width="750px" />
        </FacebookProvider>
      </FacebookContainer>
    </ReactModal>
  );
};

const FacebookContainer = styled.div`
  max-height: 80vh;
  min-height: 700px;
  height: auto;
  min-width: 750px;
  max-width: 100vw;
  overflow: auto;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Next = styled(Icon).attrs({ type: 'NextArrowIcon' })`
  position: fixed;
  left: calc(50vw + 380px);
  cursor: pointer;
  top: 45vh;
  z-index: 10001;
  font-size: 5em;

  @media screen and (max-width: 850px) {
    display: none;
  }
`;
const Prev = styled(Icon).attrs({ type: 'PrevArrowIcon' })`
  position: fixed;
  top: 45vh;
  left: calc(50vw - 450px);
  z-index: 10001;
  cursor: pointer;
  font-size: 5em;

  @media screen and (max-width: 850px) {
    display: none;
  }
`;

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  reviewUrl: PropTypes.string,
  show: PropTypes.bool
};

Modal.defaultProps = {
  reviewUrl: null,
  show: false
};

export default Modal;
