import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Line = styled.p`
  margin-bottom: 5px;
  display: flex;
  align-items: center;
`;

const BulletStyle = styled.span`
  display: inline-block;
  min-width: 8px;
  min-height: 8px;
  max-width: 8px;
  max-height: 8px;
  border-radius: 50%;
  background-color: ${p => p.theme.brand || '#BB2B22'};
  margin-right: 10px;
`;

const InvisibleBullet = styled(BulletStyle)`
  visibility: hidden;
`;

const Title = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 1em;
  color: #2d3942;
`;

const Description = styled(Title)`
  font-weight: normal;
`;

const Wrapper = styled.div``;

const Bullet = ({ title, description, ...rest }) => (
  <Wrapper {...rest}>
    <Line>
      <BulletStyle />
      <Title>{title}</Title>
    </Line>
    <Line>
      <InvisibleBullet />
      <Description>{description}</Description>
    </Line>
  </Wrapper>
);

Bullet.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};

Bullet.defaultProps = {
  title: '',
  description: ''
};

export default Bullet;
