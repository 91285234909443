import Picture from '../Picture';
import PropTypes from 'prop-types';
/* eslint-disable prefer-destructuring */
import React from 'react';
import c from '../../constant';

const baseUrl = c.GRAPH_CMS_CDN;
const breakpoints = [
  { width: 320, type: 'image', preload: true },
  { width: 720, type: 'image' },
  { width: 1024, type: 'image' },
  { width: 1440, type: 'image', preload: true }
].reverse();

// sample img
// https://asset.dolenglish.vn/resize=w:1125,fit:crop/output=format:webp/compress/SLXpLfBQa6xXFirnmeyQ

const GraphImg = ({ image, alt, ...props }) => {
  const sources = breakpoints.map(i => {
    const width = i.width;
    let handle = image.wide.handle;

    if (i.width <= 720 && image.mobile) {
      handle = image.mobile.handle;
    }

    return {
      width: i.width,
      type: i.type,
      // safari dont work well with webp
      // hence, in this case we will use default (png) instead
      // preload is a process at build time, hence we can not detect browser here.
      src:
        i.type === 'image'
          ? `${baseUrl}/resize=w:${width},fit:crop/compress/${handle}`
          : `${baseUrl}/resize=w:${width},fit:crop/output=format:webp/compress/${handle}`
    };
  });

  return (
    <Picture {...props} src={sources[0].src} alt={alt} sources={sources} />
  );
};

GraphImg.propTypes = {
  alt: PropTypes.string,
  image: PropTypes.shape({
    mobile: PropTypes.shape({
      handle: PropTypes.string
    }),
    wide: PropTypes.shape({
      handle: PropTypes.string
    })
  })
};

export default GraphImg;
