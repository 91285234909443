import Card, { Description, Slogan, Title } from '../../../components/Card';
import { H3, H4, TitleWithUnderline } from '../../../components/Typo';
import Row, { Column, Title as ColumnTitle } from '../../../components/Row';

import { BannerSlogan } from '../../../components/IndexPage/Slider';
import BlendDefinition from '../../../components/BlendDefinition';
import Container from '../../../components/Container';
import Cover from '../../../components/Cover';
import FacebookReviews from '../../../components/FacebookReviews';
import Icon from '../../../components/Icon';
import { MainLayout } from '../../../components/Layout';
import PropTypes from 'prop-types';
/* eslint-disable react/prop-types */
import React from 'react';
import SEO from '../../../components/SEO';
import Statistic from '../../../components/Statistic';
import VideoWithTitleAndDescription from '../../../components/VideoWithTitleAndDescription';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import withI18next from '../../../components/withI18next';

const BlendedSmartLearning = ({ data, pageContext }) => {
  const { seos, pageBlendeds, facebookReviews } = data.gcms;
  const seo = seos[0];
  const { t } = useTranslation('common');

  const {
    slider,
    statistics,
    definition,
    blendedDefinitions,
    smartDefinitions,
    benefit,
    benefits,
    comparison,
    comparisons
  } = pageBlendeds[0];

  return (
    <MainLayout>
      {seo && (
        <SEO
          title={seo.title}
          keywords={seo.keywords}
          description={seo.description}
          meta={pageContext.meta}
        />
      )}
      <Container>
        <BannerContainer>
          <BannerInfoContainer>
            <SloganInfo
              withDash={false}
              developer={slider.introduction}
              slogan={slider.slogan}
              subSlogan={slider.subSlogan}
              textColor={slider.textColor}
            />
          </BannerInfoContainer>
          <Cover cover={slider.image} />
        </BannerContainer>

        <PaddingContainer>
          <Statistics>
            {statistics &&
              statistics.map(({ id, title, order: iconType, descriptions }) => (
                <StatisticGroup key={id}>
                  <BlendedStatistic
                    title={title}
                    icon={iconType}
                    description={descriptions[0].text}
                  />
                  <Separator />
                </StatisticGroup>
              ))}
          </Statistics>
        </PaddingContainer>
        <BlendDefinitionTitle
          dangerouslySetInnerHTML={{ __html: definition.html }}
        />
      </Container>

      <Container bgc="#fafafa">
        <Blend>
          <Heading>blend</Heading>
          <HeadingDescription>
            {t('is the combination between')}
          </HeadingDescription>
          <BlendDefinitions>
            {blendedDefinitions &&
              blendedDefinitions.map(({ title, descriptions, asset, id }) => (
                <BlendDefinitionContent
                  key={id}
                  title={title}
                  description={descriptions[0].html}
                  backgroundImage={asset}
                />
              ))}
          </BlendDefinitions>
        </Blend>
      </Container>

      <Container>
        <Smart>
          <Heading>smart</Heading>
          <SmartVideos>
            {smartDefinitions &&
              smartDefinitions.map(def => (
                <Video
                  key={def.title}
                  title={def.title}
                  descriptions={def.descriptions.map(d => d.text)}
                  videoUrl={def.asset.wide.url}
                />
              ))}
          </SmartVideos>
        </Smart>
      </Container>

      <Container bgc="#fafafa">
        <Blend>
          <BlendDefinitionTitle
            dangerouslySetInnerHTML={{ __html: benefit.html }}
          />
          <Benefits>
            {benefits &&
              benefits.map(ben => (
                <Benefit
                  slogan={ben.order}
                  title={ben.title}
                  description={ben.descriptions[0].text}
                  key={ben.descriptions[0].text}
                />
              ))}
          </Benefits>
        </Blend>
        <FacebookReviews facebookReviews={facebookReviews} />
      </Container>

      <Container>
        <CompareContainer>
          <CompareTitle dangerouslySetInnerHTML={{ __html: comparison.html }} />

          <IconContainer>
            <IconWrapper>
              <CompareIcon type="PuzzleHead" />
            </IconWrapper>
            <IconWrapper>
              <CompareIcon type="UglyHead" />
            </IconWrapper>
          </IconContainer>

          <Row columns={comparisons.columns} />
        </CompareContainer>
      </Container>
    </MainLayout>
  );
};

const Heading = styled(H3)`
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0.5em;
`;

const HeadingDescription = styled(H4)`
  font-weight: 300;
  text-align: center;
`;

const CompareIcon = styled(Icon)`
  font-size: 5em;
  color: #e41e26;
`;

const IconContainer = styled.div`
  display: flex;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const IconWrapper = styled.div`
  flex: 1 0 50%;
  padding-left: 4em;
`;

const Benefit = styled(Card)`
  flex: 1 0 50%;
  padding: 0 1em;
  max-width: 50%;

  ${Slogan} {
    font-size: 4.5em;
    font-weight: 900;
    color: ${({ theme }) => theme.brand};
  }

  ${Title} {
    text-transform: capitalize;
  }

  ${Description} {
    font-weight: 300;
  }

  @media screen and (max-width: 768px) {
    flex: 1 0 100%;
    padding: 0;
    max-width: unset;
    margin-top: 2em;

    ${Slogan} {
      font-size: 2em;
    }

    ${Title} {
      font-size: 1.75em;
    }

    ${Description} {
      font-size: 1.25em;
      text-align: justify;
    }
  }
`;

const Benefits = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const SmartVideos = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const Video = styled(VideoWithTitleAndDescription)`
  flex: 1 0 calc(50% - 0.5em);

  &:nth-child(2n + 1) {
    margin-right: 0.5em;
  }

  &:nth-child(2n) {
    margin-left: 0.5em;
  }

  @media screen and (max-width: 768px) {
    flex: 1 0 100%;
    margin-bottom: 1.5em;

    &:nth-child(2n + 1) {
      margin-right: 0;
    }

    &:nth-child(2n) {
      margin-left: 0;
    }
  }
`;

const BlendDefinitionContent = styled(BlendDefinition)`
  @media screen and (max-width: 768px) {
    max-width: unset;
    overflow: hidden;
  }
`;

const BlendDefinitions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const BlendDefinitionTitle = styled(TitleWithUnderline)`
  font-weight: 300;
  margin: 2em 0;

  &::after {
    background-color: ${({ theme }) => theme.brand};
  }

  @media screen and (max-width: 768px) {
    margin: 1em 0;
  }
`;

const CompareTitle = styled(BlendDefinitionTitle)`
  margin: 0 0 1em;
`;

const Separator = styled.div`
  border-right: 1px solid ${({ theme }) => theme.grey};
  height: 60%;
  align-self: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const StatisticGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1em;

  @media screen and (max-width: 768px) {
    flex: 1 0 50%;
    margin: 0 0 1em;

    &:nth-child(2n + 1) {
      justify-content: flex-end;
    }

    &:nth-child(2n) {
      justify-content: flex-start;
    }
  }

  &:last-child {
    ${Separator} {
      display: none;
    }
  }
`;

const BlendedStatistic = styled(Statistic)``;

const Statistics = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-flow: row wrap;
  }
`;

const PaddingContainer = styled.div`
  padding: 1.5em 10%;

  @media screen and (max-width: 768px) {
    padding: 1.5em;
  }
`;

const Smart = styled(PaddingContainer)`
  padding: 4em 10%;
`;

const Blend = styled(PaddingContainer)`
  padding: 1.5em 10% 4em;
`;

const CompareContainer = styled(PaddingContainer)`
  padding-top: 2.5em;

  ${ColumnTitle} {
    color: black;
    margin-bottom: 1.5em;
  }

  ${Column} {
    padding-right: 2.5em;
  }
`;

const BannerContainer = styled.div`
  position: relative;
`;

const BannerInfoContainer = styled.div`
  position: absolute;
  top: 10%;
  left: 5%;
  z-index: 1;
`;

const SloganInfo = styled(BannerSlogan)`
  margin-top: 2em;
  max-width: 70%;
`;

BlendedSmartLearning.propTypes = {
  data: PropTypes.shape({}).isRequired,
  pageContext: PropTypes.shape({}).isRequired
};

BlendedSmartLearning.defaultProps = {};

export const query = graphql`
  query pageBlended {
    gcms {
      seos(where: { path: "blended" }) {
        path
        title
        description
        keywords
      }

      facebookReviews(first: 12) {
        id
        reviewUrl
        metadata
        reviewContent
        fullName
        photo {
          handle
          height
          width
        }
      }

      pageBlendeds {
        slider {
          ...Slider
        }
        statistics {
          ...CTACard
        }
        definition {
          html
        }
        benefit {
          html
        }
        comparison {
          html
        }
        blendedDefinitions {
          ...CTACard
        }
        smartDefinitions {
          ...CTACard
        }
        benefits {
          ...CTACard
        }
        comparisons {
          id
          title
          columns {
            id
            contentType
            title
            bullets {
              id
              title
              description
            }
          }
        }
      }
    }
  }
`;

export default withI18next({ ns: 'common' })(BlendedSmartLearning);
