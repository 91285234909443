import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';

import Cover from '../Cover';
import Icon from '../Icon';
import useWindowSize from '../../utils/hooks/useWindowSize';

const FacebookReview = ({
  withBorder,
  reviewContent,
  courseName,
  fullName,
  reviewUrl,
  photo,
  onClick,
  ...rest
}) => {
  const w = useWindowSize();

  return (
    <Wrapper onClick={onClick} {...rest} withBorder={withBorder}>
      <Photo>
        <Cover cover={photo} maxWidth={50} />
      </Photo>

      <Info>
        <FullName>{fullName}</FullName>
        <CourseName>{courseName}</CourseName>
      </Info>

      <a target="_blank" href={reviewUrl} rel="noreferrer noopener">
        <FacebookIcon />
      </a>
      <Review>
        <Truncate lines={w.innerWidth > 768 ? 4 : 3}>{reviewContent}</Truncate>
      </Review>
    </Wrapper>
  );
};

const Info = styled.div`
  grid-area: info;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  font-size: 1rem;
  width: 300px;
  height: 170px;
  background-color: white;
  padding: 1em;
  border: 1px solid rgba(211, 211, 211, 0.2);
  box-shadow: 10px 10px 25px 0 rgba(0, 0, 0, 0.1);
  display: grid;
  grid-gap: 0 1em;
  grid-template-columns: 48px 1fr min-content;
  grid-template-areas:
    'photo info facebook'
    'review review review';

  @media screen and (min-width: 769px) {
    font-size: 1.25rem;
    cursor: pointer;
    width: 370px;
    height: 210px;
    transition: box-shadow 0.3s;
    box-shadow: none;

    &:hover {
      box-shadow: 10px 10px 25px 0 rgba(0, 0, 0, 0.1);
    }
  }

  ${({ withBorder }) =>
    withBorder &&
    css`
      border-left: 5px solid ${({ theme }) => theme.brand};
    `};
`;

const Review = styled.p`
  color: #5d6a74;
  grid-area: review;
  font-size: 14px;
  overflow: hidden;
`;

const FacebookIcon = styled(Icon).attrs({ type: 'FacebookSquare' })`
  color: white;
  margin-top: 5px;
  font-size: 1.2em;
  grid-area: facebook;
`;

const Photo = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 100%;
  overflow: hidden;
  grid-area: photo;
`;

const FullName = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: black;
  margin-bottom: 0.5em;
`;

const CourseName = styled.p`
  font-size: 1em;
  letter-spacing: 0.4px;
  color: #787c80;
  font-variant: small-caps;
  margin: 0;
`;

FacebookReview.defaultProps = {
  onClick: () => {},
  courseName: null,
  fullName: null,
  photo: null,
  reviewContent: null,
  reviewUrl: null,
  withBorder: false
};

FacebookReview.propTypes = {
  courseName: PropTypes.string,
  fullName: PropTypes.string,
  onClick: PropTypes.func,
  photo: PropTypes.string,
  reviewContent: PropTypes.string,
  reviewUrl: PropTypes.string,
  withBorder: PropTypes.bool
};

export default FacebookReview;
