import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import Icon from './Icon';

const Statistic = ({ title, description, icon, ...rest }) => {
  return (
    <StatisticContainer {...rest}>
      <StatisticTitleGroup>
        {icon && <StatisticIcon type={icon} />}
        {title && <StatisticTitle>{title}</StatisticTitle>}
      </StatisticTitleGroup>
      {description && (
        <StatisticDescription>{description}</StatisticDescription>
      )}
    </StatisticContainer>
  );
};

const StatisticIcon = styled(Icon)`
  font-size: 2em;
`;

const StatisticContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  max-width: 250px;
`;

const StatisticTitleGroup = styled.div`
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const StatisticTitle = styled.span`
  font-size: 2em;
  font-weight: 600;
  color: ${({ theme }) => theme.brand};
  margin-left: 0.5em;

  @media screen and (max-width: 768px) {
    font-size: 1.5em;
  }
`;

const StatisticDescription = styled.span`
  font-size: 1em;
  max-width: 80%;
`;

Statistic.propTypes = {
  description: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string
};

Statistic.defaultProps = {
  description: null,
  icon: null,
  title: null
};

export default Statistic;
